import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { ResultDialogContainer, NoSites, LoaderContainer } from "./styled";
import { Button, Flex, FlexSpan, LinearProgress } from "../../ui";
import { useIndustries, useUser } from "../../hooks";
import { api } from "../../api";
import lensReportPlaceholder from "../../assets/images/lens-report-placeholder.png";
export const LensResultDialog = ({ coordinates, address }) => {
    const [user] = useUser();
    const [industries] = useIndustries();
    const [featuresCount, setFeaturesCount] = useState();
    const [loading, setLoading] = useState(true);
    const onBuyReport = useCallback(() => {
        if (!user || !industries) {
            return;
        }
        const clientReferenceId = v4();
        const newWindow = window.open();
        api.reports
            .createReport({
            user_id: user.id,
            coordinates: coordinates.join(","),
            address,
            client_reference_id: clientReferenceId,
        })
            .catch(() => null)
            .then(() => {
            newWindow.location = `${process.env.STRIPE_PAYMENT_LINK}?client_reference_id=${clientReferenceId}`;
        });
    }, [address, coordinates, industries, user]);
    useEffect(() => {
        if (coordinates) {
            api.features
                .getFeaturesCount({ coordinates })
                .then(response => setFeaturesCount(response.count))
                .finally(() => setLoading(false));
        }
    }, [coordinates]);
    if (loading) {
        return (_jsx(ResultDialogContainer, { children: _jsxs(LoaderContainer, { children: [_jsx(LinearProgress, { mb: "12px" }), "Searching, please wait..."] }) }));
    }
    return (_jsx(ResultDialogContainer, { children: featuresCount > 0 ? (_jsxs(Flex, { column: true, gap: "1.5rem", children: [_jsxs(FlexSpan, { children: [_jsxs("h2", { children: ["There ", featuresCount > 1 ? "are" : "is", " ", featuresCount, " relevant ", featuresCount > 1 ? "sites" : "site", " ", "in this area"] }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", color: "#8275AD", mt: "0.5rem", children: "We'll send a report to your email address." })] }), _jsx(FlexSpan, { children: _jsx("img", { src: lensReportPlaceholder, width: "100%", alt: "Report Placeholder" }) }), _jsx(FlexSpan, { children: _jsx(Button, { onClick: onBuyReport, width: "100%", children: "Buy Report for 190 R" }) })] })) : (_jsxs(NoSites, { children: [_jsx("h3", { children: "No suitable sites found in this area" }), "Please enter a different address"] })) }));
};
