import styled from "styled-components";
import { Button, Flex } from "../../ui";
export const AppHeaderContainer = styled(Flex) `
  justify-content: space-between;
  align-items: center;
  height: ${({ isMap }) => (isMap ? "3.5rem" : "auto")};
  min-height: ${({ isMap }) => (isMap ? "3.5rem" : "auto")};
  box-sizing: border-box;
  padding: ${({ isMap }) => (isMap ? "0 0.75rem" : 0)};

  @media only screen and (max-width: 430px) {
    & {
      height: ${({ isMap }) => (isMap ? "2.25rem" : "auto")};
      min-height: ${({ isMap }) => (isMap ? "2.25rem" : "auto")};
      
      [data-hide-on-mobile] {
        display: none;
      }
    }
  }
`;
export const AppHeaderHomeButton = styled(Button) `
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #5732d3;
  height: 2.25rem;
  margin-left: 0.5rem;

  @media (hover: hover) {
    &:hover {
      background-color: #dde0ff;
      color: ${({ disabled }) => (disabled ? "rgba(255, 255, 255, 0.35)" : "#5732d3")};
    }
  }

  @media (hover: none) {
    &:active {
      background-color: #dde0ff;
      color: ${({ disabled }) => (disabled ? "rgba(255, 255, 255, 0.35)" : "#5732d3")};
    }
  }

  @media only screen and (max-width: 430px) {
    & {
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      margin-left: 0;
    }
  }
`;
export const AppHeaderUserIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #ffcae2 0%, #5732d3 100%);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;

  @media only screen and (max-width: 430px) {
    & {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem;
    }
  }
`;
