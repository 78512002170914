import styled from "styled-components";
import { Flex } from "../../../ui";
export const AccountDialogContentLink = styled.span `
  text-decoration: underline;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: #5732d3;
    }
  }
`;
export const AccountDialogFooter = styled(Flex) `
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 0.375rem;
  font-size: 1rem;
  color: #8275ad;

  a {
    color: #8275ad;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: #5732d3;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    & {
      align-items: start;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;
