import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Flex, FlexSpan, Modal, Select } from "../../../ui";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useIndustries, useUser } from "../../../hooks";
import { api } from "../../../api";
export const ChangeIndystryDialog = () => {
    const [industry, setIndustry] = useState();
    const openDialog = useDialog();
    const [industries] = useIndustries();
    const [user, setUser] = useUser();
    const options = useMemo(() => industries?.map(item => ({ value: item.id, label: item.title })) ?? [], [industries]);
    const disabled = useMemo(() => !industry, [industry]);
    const onSave = useCallback(() => {
        if (disabled) {
            return;
        }
        api.users
            .updateMe({ industry_id: industry })
            .then(setUser)
            .finally(() => openDialog(DIALOGS.ACCOUNT_DIALOG));
    }, [disabled, industry, openDialog, setUser]);
    useEffect(() => {
        if (user) {
            setIndustry(user.industry_id);
        }
    }, [user]);
    if (!user) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Change industry" }), _jsx(FlexSpan, { children: _jsx(Select, { placeholder: "Industry/Sector", menuPlacement: window.innerWidth <= 430 ? "top" : "bottom", defaultValue: options.find(item => item.value === user.industry_id), value: options.find(item => item.value === industry), options: options, onChange: option => setIndustry(option.value) }) })] }), footer: _jsxs(Flex, { width: "auto", gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(Button, { secondary: true, height: "2.5rem", pl: "2rem", pr: "2rem", onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: "Cancel" }) }), _jsx(FlexSpan, { children: _jsx(Button, { height: "2.5rem", pl: "2rem", pr: "2rem", onClick: onSave, disabled: disabled, children: "Save" }) })] }), onClose: () => openDialog(DIALOGS.ACCOUNT_DIALOG) }));
};
