import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Button, Flex, FlexSpan, Modal, TextInput } from "../../../ui";
import { VALID_EMAIL_REGEX } from "../../../constants/regex";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useUser } from "../../../hooks";
import { api } from "../../../api";
export const ChangeEmailDialog = () => {
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailConfirmError, setEmailConfirmError] = useState(false);
    const openDialog = useDialog();
    const [user, setUser] = useUser();
    const disabled = useMemo(() => !email || !emailConfirm || emailError || emailConfirmError, [email, emailConfirm, emailConfirmError, emailError]);
    const onSave = useCallback(() => {
        if (disabled) {
            return;
        }
        api.users
            .updateMe({ email })
            .then(setUser)
            .finally(() => openDialog(DIALOGS.ACCOUNT_DIALOG));
    }, [disabled, email, openDialog, setUser]);
    if (!user) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Change email" }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { placeholder: "New Email", value: email, onChange: value => {
                                                    setEmailError(false);
                                                    setEmailConfirmError(false);
                                                    setEmail(value);
                                                }, onBlur: () => {
                                                    setEmailError(email.length > 0 && !email.match(VALID_EMAIL_REGEX));
                                                    setEmailConfirmError(email.length > 0 && emailConfirm.length > 0 && email !== emailConfirm);
                                                }, error: emailError }) }), emailError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Invalid email format" }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { placeholder: "Confirm Email", value: emailConfirm, onChange: value => {
                                                        setEmailConfirmError(false);
                                                        setEmailConfirm(value);
                                                    }, onBlur: () => setEmailConfirmError(email.length > 0 && emailConfirm.length > 0 && email !== emailConfirm), error: emailConfirmError }) }), emailConfirmError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Emails do not match" }))] }) }) })] }) })] }), footer: _jsxs(Flex, { width: "auto", gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(Button, { secondary: true, height: "2.5rem", pl: "2rem", pr: "2rem", onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: "Cancel" }) }), _jsx(FlexSpan, { children: _jsx(Button, { height: "2.5rem", pl: "2rem", pr: "2rem", onClick: onSave, disabled: disabled, children: "Save" }) })] }), onClose: () => openDialog(DIALOGS.ACCOUNT_DIALOG) }));
};
