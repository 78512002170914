import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Button, Flex, FlexSpan, Icon } from "../../ui";
import { AppHeaderContainer, AppHeaderHomeButton, AppHeaderUserIcon } from "./styled";
import { DIALOGS } from "../../constants/dialogs";
import { LoginPageUrl, PersonalPageUrl } from "../../constants/routes";
import { useDialog, useUser } from "../../hooks";
import logo from "../../assets/images/logo.png";
export const AppHeader = ({ isMap }) => {
    const navigate = useNavigate();
    const [user] = useUser();
    const openDialog = useDialog();
    return (_jsxs(AppHeaderContainer, { isMap: isMap, children: [_jsx(FlexSpan, { children: isMap ? (_jsx(AppHeaderHomeButton, { secondary: true, onClick: () => {
                        if (user) {
                            navigate(PersonalPageUrl.path);
                            return;
                        }
                        navigate(LoginPageUrl.path);
                    }, children: _jsxs(Flex, { alignItems: "center", gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(Icon, { icon: "home" }) }), _jsx(FlexSpan, { "data-hide-on-mobile": true, children: "Home" })] }) })) : (_jsx("img", { src: logo, width: "102px", alt: "OneCity Logo" })) }), _jsx(FlexSpan, { children: user ? (_jsxs(Flex, { alignItems: "center", gap: "0.5rem", style: { cursor: "pointer" }, onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: [_jsx(FlexSpan, { children: _jsx(AppHeaderUserIcon, { children: user.name[0].toUpperCase() }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: user.name }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "10px", lineHeight: "12px", color: "#8275AD", "data-hide-on-mobile": true, children: user.email })] }) })] })) : user === null ? (_jsx(Flex, { children: _jsx(FlexSpan, { children: _jsx(Button, { height: "2.75rem", onClick: () => openDialog(DIALOGS.LOGIN_DIALOG), children: "Log In" }) }) })) : null })] }));
};
