import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
export const PersonalPageContainer = styled(Flex) `
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: var(--app-height);
  background-color: #f6f7ff;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  gap: 2rem;

  @media only screen and (max-width: 430px) {
    & {
      padding-bottom: 0;
      gap: 1.5rem;
    }
  }
`;
export const PersonalHeader = styled(Flex) `
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: start;
    }
  }
`;
export const PersonalHeaderTitle = styled(FlexSpan) `
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
`;
export const PersonalHeaderSubtitle = styled(FlexSpan) `
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #8275ad;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`;
export const PersonalPageContent = styled(Flex) `
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  gap: 2rem;
  overflow: hidden;

  @media only screen and (max-width: 430px) {
    & {
      padding-bottom: 0;
      border-radius: 1rem 1rem 0 0;
    }
  }
`;
export const LocationsListHeader = styled(Flex) `
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  & > ${FlexSpan} {
    &:nth-child(2) {
      min-width: 14.375rem;
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: start;
      gap: 0.75rem;

      & > ${FlexSpan} {
        &:nth-child(2) {
          min-width: 100%;
        }
      }
    }
  }
`;
export const LocationsListTitle = styled(FlexSpan) `
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #8275ad;

  @media only screen and (max-width: 430px) {
    & {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`;
export const SearchInputClearButton = styled.span `
  display: flex;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;
export const LocationsList = styled(Flex) `
  flex-wrap: wrap;
  gap: 1.125rem;

  @media only screen and (max-width: 430px) {
    & {
      padding-bottom: 1rem;
    }
  }
`;
export const EmptinessContainer = styled(Flex) `
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
