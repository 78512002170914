import styled from "styled-components";
import { Flex } from "../Grid";
import { Icon } from "../Icon/styled";
export const IconButtonContainer = styled(Flex) `
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: ${({ active }) => (active ? "#5732d3" : "#dde0ff")};
  cursor: pointer;

  ${Icon} {
    color: ${({ active }) => (active ? "#ffffff" : "#5732d3")};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #5732d3;

      ${Icon} {
        color: #ffffff;
      }
    }
  }
`;
